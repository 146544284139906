import React from 'react'
import type { SwanStyleSheetKey, SwanFontNameEnum } from '@vp/swan'

/**
 * @deprecated replaced with string interface
 */
type CSSLoaderStyle = { [key: string]: string } & {
  _getCss: () => string
  _insertCss: () => () => void
  _getContent: () => [[string, string]]
}

export type Style = string | CSSLoaderStyle

export type InsertCss = (...styles: Style[]) => () => void

export const StyleContext = React.createContext<{ insertCss: InsertCss } | undefined >(undefined)

export const useStyles = (...styles: Style[]) => {
  const context = React.useContext(StyleContext)
  if (!context) {
    throw new Error('useStyles must be used within a StyleContextProvider')
  }
  context.insertCss(...styles)
}

export const StyleContextProvider = ({ value, children }: { value: { insertCss: InsertCss }, children?: React.ReactNode }) => {
  return (
    <StyleContext.Provider value={value}>
      {children}
    </StyleContext.Provider>
  )
}

const SwanStyleRegistryContext = React.createContext({

  registerStyleKeys: (_keys: SwanStyleSheetKey[]) => {
    /**/
  },

  registerFontKeys: (_keys: SwanFontNameEnum[]) => {
    /* */
  },
})

export const SwanStyleRegistryProvider = SwanStyleRegistryContext.Provider

export const useSwanStyleKeys = (styleKeys: SwanStyleSheetKey[]) => {
  const context = React.useContext(SwanStyleRegistryContext)
  context.registerStyleKeys(['core', 'utility', ...styleKeys])
}

export const useSwanFontKeys = (fontKeys: SwanFontNameEnum[]) => {
  const context = React.useContext(SwanStyleRegistryContext)
  context.registerFontKeys(fontKeys)
}
