import React, { useContext, useEffect, useState } from 'react'
import type VistaprintAuth from '@vp/auth'
import type { VPAuth, VPAuthIdentity, VPAuthConfig } from './auth'

/**
 * @deprecated this will migrate to a an Identity Adjacent project
 * @since 0.0.1
 */
export type IdentityContextValue = {
  auth: VPAuth | undefined
  identity: VPAuthIdentity | undefined
  signIn: () => void
  signOut: () => void
  isIdentityInitialized: boolean
  isCustomer: boolean
  isSignedIn: boolean
}

/**
 * @deprecated this will migrate to a an Identity Adjacent project
 * @since 0.0.1
 */
export const IdentityContext = React.createContext<IdentityContextValue | undefined>(
  undefined
)

/**
 * @deprecated this will migrate to a an Identity Adjacent project
 * @since 0.0.1
 */
export type IdentityProviderProps = {
  auth: VPAuthConfig
  children?: React.ReactNode
}

/**
 * @deprecated this will migrate to a an Identity Adjacent project
 * @since 0.0.1
 */
export type CreateAuthConfigOptions = {
  baseConfig: VPAuthConfig
  signInInfoText?: string
  developmentMode?: { clientID: string; redirectUriOrigin?: string } | boolean
}

/**
 * @deprecated this will migrate to a an Identity Adjacent project
 * @since 0.0.1
 */
export const createAuthConfig = ({
  baseConfig,
  signInInfoText,
  developmentMode = false,
}: CreateAuthConfigOptions): VPAuthConfig => {
  const result = {
    ...baseConfig,
    developmentMode,
    options: {
      ...baseConfig.options,
      customText: signInInfoText
        ? JSON.stringify({ signIn: { info: signInInfoText } })
        : baseConfig.options?.customText,
    },
  }

  return result
}

// some auth consumers are not checking for undefined (@vp/cart-client)
// which is logging errors in the console during SSR
const DummyAuth: VPAuth = {
  signIn () {
    /* noop */
  },
  signOut () {
    /* noop */
  },
  onUserIdentityUpdate () {
    /* noop */
  },
  getIdentity () {
    return { profile: {}, accessToken: '', authorization: '', authorizationHeader: '', isSignedIn: false }
  },
  getProfile () {
    return {}
  },
  getCanonicalId () {
    return ''
  },
  getToken () {
    return ''
  },
  getAuthorizationHeader () {
    return ''
  },
  getAuthorization () {
    return ''
  },
  isSignedIn () {
    return false
  },
}

/**
 * @deprecated this will migrate to a an Identity Adjacent project
 * @since 0.0.1
 */
export const IdentityProvider = ({
  auth: config,
  children,
}: IdentityProviderProps): React.ReactNode => {
  const [vistaprintAuth, setVistaprintAuth] = useState<typeof VistaprintAuth | undefined>(undefined)
  const [value, setValue] = useState<IdentityContextValue>({
    auth: DummyAuth,
    signIn: () => {
      /* noop */
    },
    signOut: () => {
      /* noop */
    },
    identity: undefined,
    isIdentityInitialized: false,
    isCustomer: true,
    isSignedIn: false,
  })

  useEffect(() => {
    System.import<typeof VistaprintAuth>('@vp/auth')
      .then(setVistaprintAuth)
  }, [])

  useEffect(() => {
    if (vistaprintAuth) {
      // vpauth init is document as supporting being called multiple times,
      // the first config passed in is used, subsequent calls are ignored
      vistaprintAuth.init(config, (/* identity */) => {
        const auth = new vistaprintAuth.WebAuth()
        const isCustomer =
          auth.getProfile()['https://claims.cimpress.io/is_customer'] !==
          undefined

        auth.onUserIdentityUpdate((newIdentity?: VPAuthIdentity) => {
          if (!newIdentity) {
            // don't update the context if the new identity is undefined
            return
          }

          const isSignedIn =
            Boolean(newIdentity.authorizationHeader) && newIdentity.isSignedIn

          setValue({
            auth,
            signIn: () => auth.signIn(),
            signOut: () => auth.signOut(),
            isIdentityInitialized: true,
            identity: newIdentity,
            isCustomer,
            isSignedIn,
          })
        })
      })
    }
  }, [vistaprintAuth])

  return (
    <IdentityContext.Provider value={value}>
      {children}
    </IdentityContext.Provider>
  )
}

/**
 * @deprecated this will migrate to a an Identity Adjacent project
 * @since 0.0.1
 */
export const IdentityConsumer = IdentityContext.Consumer

/**
 * @deprecated this will migrate to a an Identity Adjacent project
 * @since 0.0.1
 */
export const useIdentity = (): IdentityContextValue => {
  const context = useContext(IdentityContext)
  if (context === undefined) {
    throw new Error('useIdentity must be used within an IdentityProvider')
  }

  return context
}
