import React from 'react'
import type { HeadingLevel } from '@vp/ubik-fragment-types'

export type HeadingLevelProps = {
  headingLevel: HeadingLevel
}

const HeadingLevelContext = React.createContext<HeadingLevelProps>({
  headingLevel: 1,
})

export const HeadingLevelProvider = HeadingLevelContext.Provider

export const useHeadingLevel = () => {
  return React.useContext(HeadingLevelContext)
}
