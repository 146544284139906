import React, { createContext, useContext } from 'react'
import { createLogger, type Logger } from '@vp/ubik-logging'
import { LoggerOptions } from '@vp/ubik-fragment-types'

const LoggerContext = createContext<Logger | undefined>(undefined)

const useLogger = (): Logger => {
  const context = useContext(LoggerContext)
  if (context === undefined) {
    throw new Error('useLogger must be used within a LoggerProvider')
  }

  return context
}

const LoggerProvider = (props: { loggerOptions?: LoggerOptions, children: React.ReactNode }) => {
  const logger = createLogger(props.loggerOptions || { serviceName: 'defaultLogger' })

  return (
    <LoggerContext.Provider value={logger}>
      {props.children}
    </LoggerContext.Provider>
  )
}

export { LoggerContext, LoggerProvider, useLogger }
