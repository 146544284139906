import type { TrackedUserContext, UserContext } from '@vp/ubik-fragment-types'
import React from 'react'

type FullUserContext = {
  userContext: UserContext
  trackedUserContext: TrackedUserContext[]
}

export const UserContextContext = React.createContext<FullUserContext | undefined>(
  undefined
)
export const UserContextProvider =
  UserContextContext.Provider as unknown as React.Provider<FullUserContext>

export type UserContextProviderType = typeof UserContextProvider

export const useUserContext = () => {
  const result = React.useContext(UserContextContext)
  if (!result) {
    throw new Error('useUserContext must be used within a UserContextProvider')
  }
  return result.userContext
}

export const useUserContextTracker = () => {
  const result = React.useContext(UserContextContext)
  if (!result) {
    throw new Error('useUserContext must be used within a UserContextProvider')
  }

  return (trackedUserContext: TrackedUserContext) => {
    result.trackedUserContext.push(trackedUserContext)
  }
}
